/* You can add global styles to this file, and also import other style files */
/* You can add global styles to this file, and also import other style files */
/* @import '../node_modules/angular-archwizard/styles/archwizard.css'; */

/* Pagination to Top */
.ngx-datatable .datatable-footer {
  position: absolute;
  top: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}


.ngx-datatable .datatable-header {
  position: absolute;
  top: 50px;
}

.ngx-datatable .datatable-body {
  margin-top: 100px;
}

.text-red{
  color:red;
}

.text-white{
  color:white;
}

.text-blue{
  color:blue !important;
}

/* Items Per Page */
.itemsperpage{
  padding: 5px !important;
  color: #75787d;
  border: 1px solid #E0E0E0;
  border-radius: 0.35rem;
}
/* Table Header */
.datatable-header-cell-label{
  color: #363e59;
  font-size: 0.9rem;
  font-weight: 400;
}
.a-color{
  color:#363e59 !important;

}

.progress{
  height: 1.1rem !important;
}
.progress-bar{
  background-color: #363e59 !important;
}

.progressBarWhiteText {
  color: #ffffff;
  font-weight: 700;
  position: absolute;
  display: block;
  left: 49%;
 }

 .progressBarBlackText {
  color: #000000;
  font-weight: 700;
  position: absolute;
  display: block;
  left: 77%;
 }
 #brdcrum li:last-child a{
  color: grey !important;
}
.modal-header .close{
  font-size: 25px !important;
}
/* common colors */
.btn-video{
  padding: 0.4rem 0.5rem !important;
  background-color: white !important;
  color: #F27244 !important;
  border-color: #F27244 !important;
}
.btn-color{
  color: #F27244 !important;
}
.btn-video:hover{
  padding: 0.4rem 0.5rem !important;
  background-color: white !important;
  color: #F27244 !important;
  border-color: #F27244 !important;
  box-shadow: 0 2px 7px rgb(0 0 0 / 28%) !important;
}
.btn-play{
  padding: 0.4rem 0.5rem !important;
  background-color: white !important;
  color: #09bfbc !important;
  border-color: #09bfbc !important;
}
.btn-play:hover{
  padding: 0.4rem 0.5rem !important;
  background-color: white !important;
  color: #09bfbc !important;
  border-color: #09bfbc !important;
  box-shadow: 0 2px 7px rgb(0 0 0 / 28%) !important;
}

.btn-play-over{
  padding: 0.4rem 0.5rem !important;
  background-color: transparent !important;
  color: transparent !important;
  border-color: transparent !important;
}
.btn-play-over:hover{
  padding: 0.4rem 0.5rem !important;
  background-color: transparent !important;
  color: rgb(165, 165, 165)!important;
  border-color:  transparent !important;
  /* box-shadow: 0 2px 7px rgb(0 0 0 / 28%) !important; */
}
/* .btn-play-start{
  padding: 0.4rem 0.5rem !important;
  background-color: transparent !important;
  color: transparent !important;
  border-color: transparent !important;
} */
.btn-play-start{
  padding: 0.4rem 0.5rem !important;
  background-color: transparent !important;
  color: rgb(255, 255, 255)!important;
  border-color:  transparent !important;
  /* box-shadow: 0 2px 7px rgb(0 0 0 / 28%) !important; */
}

.btn-play-start:hover{
  padding: 0.4rem 0.5rem !important;
  background-color: transparent !important;
  color: rgb(255, 255, 255)!important;
  border-color:  transparent !important;
  box-shadow: none !important;
}

.btn-play-pause{
  padding: 0.4rem 0.5rem !important;
  background-color: transparent !important;
  color: rgb(255, 255, 255)!important;
  border-color:  transparent !important;
}
.btn-play-pause:hover{
  padding: 0.4rem 0.5rem !important;
  background-color: transparent !important;
  color: rgb(255, 255, 255)!important;
  border-color:  transparent !important;
  /* box-shadow: 0 2px 7px rgb(0 0 0 / 28%) !important; */
}
/* .btn-play-button{
  padding: 0.4rem 0.5rem !important;
  background-color: transparent !important;
  color: grey !important;
  border-color: transparent !important;
}
.btn-play-button:hover{
  padding: 0.4rem 0.5rem !important;
  background-color: transparent !important;
  color: #grey !important;
  border-color: transparent !important;
  box-shadow: 0 2px 7px rgb(0 0 0 / 28%) !important;
} */

.btn-arrow{
  background-color: #363e59 !important;
  padding: 0.4rem 0.5rem !important;
  border-color: #363e59 !important;
}
.btn-arrow:hover{
  background-color: #363e59 !important;
  padding: 0.4rem 0.5rem !important;
  border-color: #363e59 !important;
  box-shadow: 0 2px 7px rgb(0 0 0 / 28%) !important;
}
.btn-blue{
  background-color: #363e59 !important;
}
.btn-orange{
  background-color: #F27244 !important;
}
.btn-green{
  background-color: #09bfbc !important;
  border-color: #09bfbc !important;
  color: white !important;
}
.btn-green-outline {
  border: 1px solid #09bfbc !important;
  background-color: transparent;
  color: #09bfbc;
}
.btn-green-outline:hover {
  background-color: #09bfbc!important;
  color: #fff!important;
}

.btn-blue-outline {
  border: 1px solid;
  border-color: #363e59!important;
  background-color: transparent;
  color: #363e59!important;
}

.btn-blue-outline:hover {
    background-color: #363e59 !important;
    color: #fff !important;
}

.btn-orange-outline {
  border: 1px solid;
  border-color: #F27244!important;
  background-color: transparent;
  color: #F27244!important;
}

.btn-orange-outline:hover {
    background-color: #F27244 !important;
    color: #fff !important;
}
.text-green{
  color: #09bfbc !important;
}
.txt-font{
  font-size: 11px !important;
}
.mt15{
  margin-top: 17px;
}
.nav-top{
  margin-top: -15px !important;
}
@media screen and (orientation:landscape) and
(min-device-width: 320px) and (max-device-width: 450px){
  .nav-top{
    margin-top: 15px !important;
  }
}
@media screen and (orientation:landscape) and
(min-device-width: 800px) and (max-device-width: 1280px){
  .nav-top{
    margin-top: 15px !important;
  }
}
@media only screen   
and (orientation : portrait)   
and (-webkit-min-device-pixel-ratio: 1)   
and (min-device-width : 768px)   
and (max-device-width : 1007px)   {
  .btn-font{
    font-size: 10px !important;
    line-height: 1rem !important;
  }
  .txt-font{
    font-size: 8px !important;
  }
  .nav-top{
    margin-top: 15px !important;
  }
  .btn-play{
    padding: 0.3rem 0.4rem !important;
  }
  .btn-video{
    padding: 0.3rem 0.4rem !important;
  }
  .btn-mrg{
    margin-top: 5px;
  }
  .mt15{
    margin-top: 15px;
  }
  .rightRecording{
    position: fixed;
    top: 20%;
    right: 0;
    width: auto;
    background: #bbb0;
    z-index: 999;
    height: 20px;
  }
  .bread-mt-4{
    margin-top: -4px !important;
  }
}

@media only screen   
and (min-width: 1370px)  
and (max-width: 1605px)  
and (orientation: portrait)
{
    .btn-font{
      font-size: 20px !important;
    }
    .storeslide{
      padding: 1.5rem !important;
    }
   
}


.video-pause {
  -webkit-animation: seconds 1.0s forwards;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-delay: 1s;
  animation: seconds 1.0s forwards;
  animation-iteration-count: 1;
  animation-delay: 1s;
  position: relative;

}
@-webkit-keyframes seconds {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    left: -9999px;
    position: absolute;
  }
}
@keyframes seconds {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    left: -9999px;
    position: absolute;
  }
}
.font-10{
  font-size: 10px !important;
}
.active-tab, .active-tab:active, .active-tab:hover, .active-tab:focus{
  color:#f27244 !important;
  }