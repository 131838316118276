// styles.scss

@import '~video.js/dist/video-js.css';
@import '~videojs-record/dist/css/videojs.record.css';

$aw-colors: (
  '_': (
    'default': (
      'border-color-default': #E6E6E6,
      'background-color-default': #E6E6E6,
      'symbol-color-default': #E6E6E6,
      'border-color-hover': darken(#E6E6E6, 10%),
      'background-color-hover': darken(#E6E6E6, 5%),
      'symbol-color-hover': darken(#E6E6E6, 10%),
    ),
    'current': (
      'border-color-default': #2154bd80,
      'background-color-default': #2154bd80,
      'symbol-color-default': #E6E6E6,
      'border-color-hover': darken(#2154bd80, 10%),
      'background-color-hover': darken(#2154bd80, 5%),
      'symbol-color-hover': darken(#E6E6E6, 10%),
    ),
    'done': (
      'border-color-default': #363e59,
      'background-color-default': #363e59,
      'symbol-color-default': #E6E6E6,
      'border-color-hover': darken(#363e59, 10%),
      'background-color-hover': darken(#363e59, 5%),
      'symbol-color-hover': darken(#E6E6E6, 10%),
    ),
    'optional': (
      'border-color-default': #E6E6E6,
      'background-color-default': #E6E6E6,
      'symbol-color-default': #E6E6E6,
      'border-color-hover': darken(#E6E6E6, 10%),
      'background-color-hover': darken(#E6E6E6, 5%),
      'symbol-color-hover': darken(#E6E6E6, 10%),
    ),
    'editing': (
      'border-color-default': #2154bd80,
      'background-color-default': #2154bd80,
      'symbol-color-default': #E6E6E6,
      'border-color-hover': darken(#2154bd80, 10%),
      'background-color-hover': darken(#2154bd80, 5%),
      'symbol-color-hover': darken(#E6E6E6, 10%),
    ),
  ),
  'large-filled-symbols': (
    '_': (
      'symbol-color-default': rgb(250, 250, 250),
      'symbol-color-hover': rgb(255, 255, 255),
    )
  )
);
@import '../node_modules/angular-archwizard/styles/archwizard.scss';

// $aw-colors: (
//   '_': (
//     'default': (
//       'border-color-default': #76b900,
//       'background-color-default': null,
//       'symbol-color-default': #68aa20,
//       'border-color-hover': #569700,
//       'background-color-hover': null,
//       'symbol-color-hover': #569700,
//     ),
//     'current': (
//       'border-color-default': #bbdc80,
//       'background-color-default': #bbdc80,
//       'symbol-color-default': #808080,
//       'border-color-hover': #76b900,
//       'background-color-hover': #76b900,
//       'symbol-color-hover': #808080,
//     )
//   )
// );





